import React from 'react'

import Layout from '../../components/layout'
import SEO from '../../components/seo'
import InstagramIcon from '@material-ui/icons/Instagram'
import { isMobile } from 'react-device-detect'

const ContactPage = () => (
  <Layout>
    <SEO title="Contact" />
    <h1>Contact</h1>
    <div
      style={{
        display: 'flex',
        flexDirection: isMobile ? 'column' : 'row',
        justifyContent: isMobile ? 'center' : 'flex-start',
        alignItems: isMobile ? 'center' : 'flex-start',
        marginTop: isMobile ? '0' : '120px',
      }}
    >
      <a
        href="https://www.instagram.com/tphoto360/?hl=en"
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-start',
          alignItems: 'center',
        }}
      >
        <InstagramIcon />
        <strong style={{ margin: '0 1rem 0 1rem' }}>
          Follow this project and others on Instagram
        </strong>
      </a>
      <form
        id="contact-form"
        action="https://formspree.io/trmeier08@gmail.com"
        method="POST"
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: isMobile ? 'center' : 'flex-start',
          alignItems: isMobile ? 'center' : 'flex-start',
          marginTop: isMobile ? '40px' : '',
          width: '100%',
        }}
      >
        <input
          type="text"
          name="name"
          placeholder="Name"
          style={{
            margin: '.25rem 0 .25rem 0',
            borderRadius: '4px',
            width: isMobile ? '85%' : '50%',
          }}
        />
        <input
          type="email"
          name="_replyto"
          placeholder="E-mail"
          style={{
            margin: '.25rem 0 .25rem 0',
            borderRadius: '4px',
            width: isMobile ? '85%' : '50%',
          }}
        />
        <textarea
          name="message"
          rows="4"
          placeholder="Message"
          style={{
            margin: '.25rem 0 .25rem 0',
            borderRadius: '4px',
            width: isMobile ? '85%' : '50%',
          }}
        ></textarea>
        <input
          type="submit"
          value="Send"
          style={{
            margin: '.25rem 0 .25rem 0',
            borderRadius: '4px',
            width: isMobile ? '85%' : '50%',
          }}
        />
      </form>
    </div>
  </Layout>
)

export default ContactPage
